import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"
import "../../css/page-staff.scss"

const StaffPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Staff</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<div className="staffGrid">
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/dave.jpg" alt="Dave" class="staffImage" />
							</div>
							<h5>Dave Havel</h5>
							<p>Executive Director</p>
							<p><a href="mailto:dave.havel@habitatnorthwoods.org">Email</a></p>
						</div>
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/dennis-shimeck.jpg" alt="Denis" class="staffImage" />
							</div>
							<h5>Dennis Shimeck</h5>
							<p>ReStore Manager</p>
							<p><a href="mailto:dennis.shimeck@habitatnorthwoods.org">Email</a></p>
						</div>
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/justin-kriesel.jpg" alt="Justin" class="staffImage" />
							</div>
							<h5>Justin Kriesel</h5>
							<p>ReStore Assistant Manager</p>
							<p><a href="mailto:justin.kriesel@habitatnorthwoods.org">Email</a></p>
						</div>
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/Ann.jpeg" alt="Ann Sorensen" class="staffImage" />
							</div>
							<h5>Ann Sorensen</h5>
							<p>Contruction Manager</p>
							<p><a href="mailto:ann.sorensen@habitatnorthwoods.org">Email</a></p>
						</div>
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/jennie.jpg" alt="Jennie Kotula" class="staffImage" />
							</div>
							<h5>Jennie Kotula</h5>
							<p>Volunteer Coordinator</p>
							<p><a href="mailto:jennie.kotula@habitatnorthwoods.org">Email</a></p>
						</div>
					</div>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default StaffPage

export const Head = () => (
	<Seo
        title="Staff | Habitat For Humanity Northwoods Wisconsin"
    />
)